import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { connect, useSelector } from "react-redux";
import { fetchAgencies } from "../state";
import { GlobalStyle } from "../style/globalStyles";
import { Layout } from "../components/Layout";
import { AgenciesSection } from "../style/agencies";
import { AgenciesTable } from "../components/AgenciesTable";
import { BallBeat } from "react-pure-loaders";
function AgenciesContainer({ agenciesData, fetchAgencies }) {
  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);

  useEffect(() => {
    if (!loginBool) {
      navigate("/login");
    }
    // console.log("fetchAgencies being called")
    if (agenciesData.agencies.length === 0) {
      fetchAgencies();
    }
  }, [fetchAgencies]);
  // console.log(agenciesData);
  return (
    <>
      <GlobalStyle />
      <Layout>
        <AgenciesSection>
          {agenciesData.loading ? (
            <h2>
              Loading Travel Partners{" "}
              <span>
                <BallBeat color={"#123abc"} loading={agenciesData.loading} />
              </span>{" "}
            </h2>
          ) : agenciesData.error ? (
            <>
              <h2>ERROR ⚠️ FETCHING Travel Partners</h2>
              <br />
              <p style={{ color: "grey" }}>{agenciesData.error}</p>
              <br />
              <br />
            </>
          ) : (
            <>
              <h1>Travel Partners List</h1>
              <br />
              <AgenciesTable agencies={agenciesData.agencies} />
            </>
          )}
        </AgenciesSection>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    agenciesData: state.agencies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAgencies: () => dispatch(fetchAgencies()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgenciesContainer);
