import React from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";

export const AgenciesTable = ({ agencies }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "businessName",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Contact Name",
        accessor: "contactName",
      },
      {
        Header: "Mobile",
        accessor: "primaryMobileNo",
      },
      {
        Header: "Type",
        accessor: "partnerTypeDesc",
      },
      {
        Header: "Active",
        accessor: (isActive) => {
          return isActive.isActive ? "Active ✅" : "Not Active ❌";
        },
      },
      {
        Header: "Approved",
        accessor: (isApproved) => {
          return isApproved.isApproved ? "Approved ✅" : "Not Approved ❌";
        },
      },
    ],
    []
  );

  var data = React.useMemo(() => agencies, []);

  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          // onClick: (e) => console.log("row clicked", rowInfo),
        };
      }}
      // Pass the page url here to navigate to onClick
      path={"/agency"}
      extras={true}
      columns={columns}
      data={data}
    />
  );

  return <TableStyles>{agencies ? tableRender : " "}</TableStyles>;
};
